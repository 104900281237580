export default {
    PORT : 'https://kirayewalabackend.onrender.com/upload/',
    OWNER_SIGNIN: '/owner/signin',
    OWNER_SIGNUP: '/owner/signup',
    PROPERTY_LIST:'/property/list',
    NEAR_BY_HOUSE_LIST:'/property/nearByHouse',
    ADD_TO_WISHLIST:'/tenant/addToWishList',
    POST_PROPERTY:'/owner/addproperty',
    REQUEST_BY_TENANTS:'/owner/request',
    VIEW_PROPPERTY_OF_OWNER:'/owner/view_porperty',
    TAKE_SUBSCRIPTION:'/owner/subscription',
    POST_PROPERTY_DETAILS:'/owner/propertyDetails',
    SEARCH:'/tenant/search',
    REMOVE_PROPERTY_OF_OWNER:'/owner/removePropertyById',
    VIEW_WISHLIST:'/tenant/viewWishlist',
    SHOW_SUBSCRIPTION : '/owner/showSubscription',
    CATEGORY_COUNT:'/property/categoryCount',
    SEND_OTP:'/tenant/sendOtp',
    HOUSE_REQUEST:'/tenant/houseRequest',
    REMOVE_PROPERTY_DETAILS: '/owner/removePropertyDetails',
    DELETE_TENANT_REQUEST:'/owner/deleteTenantRequest',
    CATEGORY_COUNT:'/property/categoryCount',
    VIEW_ADMIN:'/owner/viewAdmin',
    UPDATE_BALANCE:'/owner/updateBalance',
    EMAIL_SEND:'/tenant/sendOtp',
    PROPERTY_DETAILS:'property/propertyDetails',
    REMOVE_FROM_WISHLIST:'/tenant/removeFromWishList',
    REQUEST_LIST:'/tenant/requestList',
    USER_CHECK : '/user/checkUser',
    USER_SINGIN : '/user/createUser',
    SEARCH_WITH_CATEGORY:'/tenant/searchWithCategory',
    USER_CHECK : '/user/checkUser',
    USER_SINGIN : '/user/createUser',
    PROPERTY_DETAILS:'/property/propertyDetails',
    REMOVE_FROM_WISHLIST:'/tenant/removeFromWishList',
    REQUEST_LIST:'/tenant/requestList',
    PROPERTY_BY_FURNISHING:'/owner/findPropertyByCategory',
    PROPERTY_BY_CATEGORY:'/owner/findByCategory',
    UPDATE_REQUEST:'/owner/updateRequest'
}