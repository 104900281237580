export default {
    NAME:'Kiraye',
    COMPANY_NAME:'Kiraye wala.com .in 201010',
    ADDRESS:'MadhoVastika Indore,MP',
    CONTACT_NO:'95411563385',
    EMAIL_ID:'the.ingeious@gmail.com',
    ABOUT_US:'Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismodconvallis velit, eu auctor lacus vehicula sit amet.',
    FACEBOOK_LINK:"",
    TWITTER_LINK:"",
    LINKED_IN_LINK:"",
    GITHUB_LINK:''
}