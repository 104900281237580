import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import { Route, Routes } from 'react-router';
import Signin from './Components/User/signin';
import Flate from './Components/House/PostPropertyForms/Flate/Flate';
import { BrowserRouter } from 'react-router-dom';
import $ from "jquery"
function App() {
  return<>
    <Home/>
  </>
}

export default App;
