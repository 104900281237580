import './waves.css';
function Waves() {
    return <>
        {/* <div class="ocean">
            <div class="wave"></div>
            <div class="wave"></div>
        </div> */}
        
    </>
}

export default Waves;